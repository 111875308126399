/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar, Box, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { RootState } from '../../store/type';
import { StyledCustomizedDivider, StyledCustomizedWrapper, StyledCustomizedWrapper1 } from '../Dashboard/Dashboard.styled';
import { UserProfiles } from '../Dashboard/components/UserProfile';
import { GrowthAppraisal, GrowthAppraisalContainer } from './Growth.styled';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { getAuthHeader, getCurrentYear } from '../../utils/utils';
Chart.register(...registerables);

const Growth = () => {
  const user = useSelector((state: RootState) => state.user.employee);
  const userId = user?.id;
  const [showPercentage, setShowPercentage] = useState(false);
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [chartData, setChartData] = useState<number[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [isAppraisalEligible, setIsAppraisalEligible] = useState(false);
  const [visiblePercentage, setVisiblePercentage] = useState();

  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear()
  )?.toString();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/admin/employee-eligibility/get?user_id=${userId}`,
          method: 'GET',
          params: {
            year: currentYear,
          },
          headers: getAuthHeader,
        });
        setIsAppraisalEligible(response?.data?.is_appraisal_eligible);
        setVisiblePercentage(response.data.percent);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchData();
  }, [currentYear, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/admin/growth/${userId}`,
          method: 'GET',
          headers: getAuthHeader,
        });
        if (response && response.data) {
          const extractedData = response.data.map(item => item.value);
          const extractedLabels = response.data.map(item => item.year);
  
          setChartData(extractedData);
          setChartLabels(extractedLabels);
        } else {
          console.warn('Empty or invalid response received:', response);
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };
    fetchData();
  }, [userId]);

  useEffect(() => {
    const myChart = chartRef.current;
    const ctx = myChart.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    if (ctx) {
      chartInstanceRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [
            {
              data: chartData,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: 'Hike%',
                font: {
                  size: 18,
                  weight: 'bold',
                },
                color: '#000',
              },
            },
            x: {
              title: {
                display: true,
                text: 'Year',
                font: {
                  size: 18,
                  weight: 'bold',
                },
                color: '#000',
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Growth Chart',
              font: {
                size: 20,
              },
              color: '#000',
              position: 'top',
              align: 'start',
              padding: {
                bottom: 20, // Adjust this value for more space between the title and chart
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData, chartLabels]);

  const handleToggleVisibility = () => {
    setShowPercentage(!showPercentage);
  };

  return (
    <>
      <StyledCustomizedWrapper>
        <UserProfiles {...user} />
        {isAppraisalEligible && (
          <>
            <StyledCustomizedDivider />
            <GrowthAppraisal>
              <GrowthAppraisalContainer className="rank-list">
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'center', sm: 'flex-start' },
                  }}
                >
                  <ListItem>
                    <ListItemAvatar sx={{ minWidth: 0, marginRight: 1 }}>
                      <Avatar src="/Growth.svg" sx={{ width: 40, height: 40 }} />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        '& .MuiListItemText-primary': {
                          whiteSpace: 'nowrap',
                          textOverflow: 'clip',
                          marginBottom: '4px',
                        },
                        '& .MuiListItemText-secondary': {
                          display: 'flex',
                          alignItems: 'center',
                          padding: 0,
                        },
                      }}
                      primary="Appraisal Percentage"
                      secondary={(
                        <Box style={{ display: 'flex', alignItems: 'start' }}>
                          {showPercentage ? (
                            <span>
                              {visiblePercentage}
                              %
                            </span>
                          ) : <span>XXX%</span>}
                          {showPercentage ? <VisibilityIcon onClick={handleToggleVisibility} style={{ color: 'Blue' }} /> : <VisibilityOffIcon onClick={handleToggleVisibility} style={{ color: 'Blue' }} />}
                        </Box>
                      )}
                    />
                  </ListItem>
                </List>
              </GrowthAppraisalContainer>
            </GrowthAppraisal>
          </>
        )}
      </StyledCustomizedWrapper>
      <StyledCustomizedWrapper1>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: { xs: 400, sm: 500, md: 600 },
                    overflow: 'hidden',
                  }}
                >
                  <canvas ref={chartRef} id="myChart" style={{ width: '100%', height: '100%' }} />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </StyledCustomizedWrapper1>
    </>
  );
};

export default Growth;
