/* eslint-disable */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Box, List, ListItem, ListItemText, IconButton, InputAdornment, TextField, Avatar, Typography, Pagination, Skeleton, Stack, Grid, CircularProgress,
} from '@mui/material';
import DOMPurify from 'dompurify';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { TabContext, TabPanel } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { TableColumn, TableData } from './type';
import {
  ListTableWrapper, AntSwitch, StyledDropdownWrapper, StyledLiveRankButton, StyledMenu, StyledMenuItem, StyledLiveRankIcon, StyledFormControlLabel,
} from './ListTable.styled';
import { getUniqueObjectsBasedOnKey } from '../../utils/utils';

interface ListTableProps {
  title: string;
  tabValue: string;
  columns: TableColumn[];
  data: TableData[];
  actions: {
    icon: React.ReactNode;
    label: string;
    color: string;
    onClick: (item: TableData) => void;
  }[];
  itemsPerPage?: number;
  onActionClick?: (action: any, item: TableData) => void;
  isStatus?: boolean;
  DropdownFilter?: any;
  loading?: boolean;
}

function LiveRankIcondown() {
  return (
    <StyledLiveRankIcon className="live-icon" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99997 10C7.71322 10 7.4265 9.87995 7.20788 9.64036L0.328227 2.09719C-0.109409 1.61736 -0.109409 0.839379 0.328227 0.359734C0.765686 -0.119911 1.4751
      -0.119911 1.91277 0.359734L7.99997 7.03435L14.0872 0.359967C14.5249
      -0.119678 15.2342 -0.119678 15.6716 0.359967C16.1095 0.839612 16.1095 1.61759 15.6716 2.09743L8.79207 9.6406C8.57334 9.88023 8.28662 10 7.99997 10Z"
        fill="#848282"
      />
    </StyledLiveRankIcon>
  );
}
function LiveRankIconUp() {
  return (
    <StyledLiveRankIcon className="live-icon" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00003 0.499999C8.28678 0.499999 8.5735 0.620047 8.79212 0.859637L15.6718
        8.40281C16.1094 8.88264 16.1094 9.66062 15.6718 10.1403C15.2343 10.6199 14.5249 10.6199 14.0872 10.1403L8.00003 3.46565L1.91279 10.14C1.47515 10.6197 0.765811 10.6197 0.328387 10.14C-0.109461 9.66039 -0.109461 8.88241
        0.328387 8.40257L7.20793 0.859403C7.42666 0.619774 7.71338 0.499999 8.00003 0.499999Z"
        fill="#2559C3"
      />
    </StyledLiveRankIcon>
  );
}

function ListTable({
  title, tabValue, columns, data, actions, itemsPerPage = 10, onActionClick, isStatus = true, DropdownFilter = '', loading = false,
}: ListTableProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = React.useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const uniqueDepartments = DropdownFilter !== '' ? [...new Set(data.map((item) => item[DropdownFilter]))] : [];
  const temp = data.filter((itm) => (filter.length !== 0 ? filter.includes(itm[DropdownFilter]) : itm));
  const filteredData = isLoading
    ? new Array(itemsPerPage).fill(null) // Show skeletons when loading
    : temp.filter((item) => columns.some((column) => {
      const value = item[column.accessor];
      return value && value.toString().toLowerCase().includes(searchQuery.toLowerCase());
    }));

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = filteredData.slice(startIndex, endIndex);
  const totalRecords = filteredData.length;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage when tab changes
  }, [tabValue]);

  // Update currentPage based on the total pages available for the new tab's data
  useEffect(() => {
    const totalPagesForCurrentTab = Math.ceil(filteredData.length / itemsPerPage);
    if (currentPage > totalPagesForCurrentTab) {
      setCurrentPage(1); // Reset currentPage if it exceeds total pages for current tab's data
    }
  }, [filteredData, currentPage, itemsPerPage]);

  const handleDepartment = (event: any, clickedDepartment: string) => {
    const { checked } = event.target;
    setSearchQuery('');
    if (checked) {
      setFilter([...filter, clickedDepartment]);
    } else {
      const filterStatus = filter.filter((element) => element !== clickedDepartment);
      setFilter(filterStatus);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Change the delay time as needed

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <Grid>
      <div className="artifact-main-table">
        <ListTableWrapper>
          <div className="list_table-main-table">
            <Grid container style={{ marginBottom: 20 }} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h4" gutterBottom>
                  {title} Performance
                </Typography>
              </Grid>
              <Grid item>
                <div className="table-row-item srch_position" style={{ border: 0 }}>
                  <Box className="actions">
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={searchQuery}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchQuery(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="large">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
            <TabContext value={tabValue}>
              <TabPanel value={tabValue} className="override-tabpanel">
                <div className="table-row-item-cell">
                  {columns.map((column) => (
                    <Box key={column.accessor} className="list_table-table-head">
                      {column.header}
                    </Box>
                  ))}
                  {isStatus && <Box className="list_table-table-head list-table-status-head">Status</Box>}
                  {Array.isArray(actions) && <Box className="list_table-table-head list-table-action-head">Report</Box>}
                </div>
                {isLoading ? (
                  Array.from({ length: itemsPerPage / 2 }).map((_, index) => (
                    <div className="table-row-item" key={index}>
                      {columns.map((column) => (
                        <Box className="list-list_table-box-wrap" key={column.accessor}>
                          <List>
                            <ListItem className="list-list_table">
                              <Skeleton width={300} animation="wave" variant="text" />
                            </ListItem>
                          </List>
                        </Box>
                      ))}
                      {isStatus && (
                        <Box className="list-list_table-box-wrap" key="status">
                          <List>
                            <ListItem className="list-list_table">
                              <Skeleton width={150} animation="wave" variant="text" />
                            </ListItem>
                          </List>
                        </Box>
                      )}
                      {Array.isArray(actions) && (
                        <Box className="list-list_table-box-wrap" key="actions">
                          <List>
                            <ListItem className="list-list_table">
                              <Skeleton width={150} animation="wave" variant="text" />
                            </ListItem>
                          </List>
                        </Box>
                      )}
                    </div>
                  ))
                ) : loading ? (
                  <p style={{ textAlign: 'center', fontWeight: 400 }}>
                    <CircularProgress />
                  </p>
                ) : visibleData.length === 0 ? (
                  <p style={{ textAlign: 'center', fontWeight: 400 }}>No data to display</p>
                ) : (
                  visibleData.map((item, index) => (
                    <div className="table-row-item" key={index}>
                      {columns.map((column) => (
                        <Box className="list-list_table-box-wrap" key={column.accessor}>
                          <List>
                            <ListItem className="list-list_table">
                              {column.type === 'image' || column.type === 'Image' ? (
                                <Avatar alt="Image" src={decodeURIComponent(item[column.accessor])} />
                              ) : column.type === 'html' || column.type === 'HTML' ? (
                                // Render HTML content
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[column.accessor]) }} />
                              ) : (
                                // Render plain text
                                <ListItemText
                                  className="listIem-text"
                                  primary={
                                    column.accessor === 'business unit' 
                                      ? <span title={item.profile?.department}>{item.profile?.department}</span> 
                                      : (item.email === item[column.accessor] 
                                          ? <span title={item[column.accessor]}>{item[column.accessor]}</span> 
                                          : item[column.accessor]
                                        )
                                  }
                                />
                              )}
                            </ListItem>
                          </List>
                        </Box>
                      ))}
                      {isStatus && (
                        <Box className="status-field-wrap" m={2}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Inactive</Typography>
                            <AntSwitch
                              defaultChecked={item.status === 1}
                              inputProps={{ 'aria-label': 'ant design' }}
                              onClick={() => {
                                if (onActionClick) {
                                  onActionClick({ label: 'status' }, item);
                                }
                              }}
                            />
                            <Typography>Active</Typography>
                          </Stack>
                        </Box>
                      )}
                      <Box className="actions-field-wrap">
                        {actions.map((action, actionIndex) => (
                          <span key={actionIndex}>
                            <IconButton
                              // color={action.color}
                              key={index}
                              size="large"
                              className={action.color ? action.color : 'primary-icon'}
                              onClick={() => {
                                if (onActionClick) {
                                  onActionClick(action, item);
                                }
                              }}
                            >
                              {action.icon}
                            </IconButton>
                          </span>
                        ))}
                      </Box>
                    </div>
                  ))
                )}
              </TabPanel>
            </TabContext>
          </div>
          <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
            <div className="pgntn_box">
              <Typography className="showing_rslt" variant="body2" color="textSecondary">
                Showing
                {' '}
                {visibleData.length}
                {' '}
                records out of
                {' '}
                {totalRecords}
                {' '}
                total.
              </Typography>
              <Pagination count={totalPages} page={currentPage} onChange={(event, page) => setCurrentPage(page)} color="primary" size="large" className="pagination cs_pgntn" siblingCount={1} boundaryCount={1} showFirstButton showLastButton />
            </div>
          </Box>
        </ListTableWrapper>
      </div>
    </Grid>
  );
}

export default ListTable;
