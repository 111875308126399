/* eslint-disable */
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {jwtDecode} from 'jwt-decode';
const isTokenExpired = () => {
  const token = localStorage.getItem('token');
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < Math.floor(Date.now() / 1000);
  } catch (error) {
    return true;
  }
};

const TokenCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isTokenExpired()) {
      localStorage.removeItem('token');
      navigate('/login');
    }
  }, [navigate, location]);

  return null;
};
export default TokenCheck;