/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { RootState } from '../../store/type';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { getAuthHeader, getCurrentYear } from '../../utils/utils';
Chart.register(...registerables);

const GrowthChart = () => {
  const user = useSelector((state: RootState) => state.user.employee);
  const userId = user?.id;
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [chartData, setChartData] = useState<number[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/admin/growth/${userId}`,
          method: 'GET',
          headers: getAuthHeader,
        });
        if (response && response.data) {
          const extractedData = response.data.map(item => item.value);
          const extractedLabels = response.data.map(item => item.year);
  
          setChartData(extractedData);
          setChartLabels(extractedLabels);
        } else {
          console.warn('Empty or invalid response received:', response);
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };
    fetchData();
  }, [userId]);

  useEffect(() => {
    const myChart = chartRef.current;
    const ctx = myChart.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    if (ctx) {
      chartInstanceRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [
            {
              data: chartData,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: 'Hike%',
                font: {
                  size: 18,
                  weight: 'bold',
                },
                color: '#000',
              },
            },
            x: {
              title: {
                display: true,
                text: 'Year',
                font: {
                  size: 18,
                  weight: 'bold',
                },
                color: '#000',
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Growth Chart',
              font: {
                size: 20
              },
              color: '#000',
              position: 'top',
              align: 'start',
              padding: {
                bottom: 20,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData, chartLabels]);

  return (
    <Card sx={{ mt: 2, width: '100%' }}>
        <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: { xs: 400, sm: 500, md: 600 },
                }}
                >
                <canvas ref={chartRef} id="myChart" style={{ width: '100%', height: '100%' }} />
            </Box>
            </Grid>
        </Grid>
        </CardContent>
    </Card>
  );
};

export default GrowthChart;
