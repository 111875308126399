/* eslint-disable */
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthHeader } from '../../utils/utils';
import { viewCloneColumn } from '../../libs/Constant';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { ArtifactWrapper } from './ViewClone.styled';
import DialogBoxforCloneApproval from './DialogBoxforCloneApproval';
import { showSuccessSnackbar, showErrorSnackbar } from '../../components/Snackbar/Snackbar';
import { checkPermissions } from '../../utils/roles/permissionUtils';
import {getCurrentYear } from '../../utils/utils';
const ViewClone = () => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showReportee, setShowReportee] = useState([]);
  const [showClonedReportee, setClonedReportee] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [isEditMode, setEditMode] = useState(false);
  const { employee = {} } = useSelector((state: any) => state.user) || {};
  const selectedYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();
  const currentFinancialYear=`${selectedYear}-${parseInt(selectedYear)+1}`;
  const location = useLocation();
  const managersEmailState = location?.state?.email || '';
  const { id } = location.state;
  const handleAction = () => {
    setEditMode(true);
    const filter = showReportee.map((item) => ({
      ...item,
      isChecked: showClonedReportee.includes(item.user_id),
    }));
    setShowReportee(filter);
  };
  const hasClonePermission = checkPermissions('PMSCLONE:SUBCLONEDET', ['Write'], true);

  const checkCloneArrayExists = (user_id) => showClonedReportee.some((item) => item === user_id);
  useEffect(() => {
    const loggedInUserEmail = managersEmailState || employee?.email;
    const fetch = async () => {
      try {
        const { data }: any = await CommHandler.request({
          url: `${config.apiBaseUrl}/cloning?email=${encodeURIComponent(loggedInUserEmail)}`,
          method: 'GET',
          headers: getAuthHeader,
          params: {
            financialYear: currentFinancialYear,
          },
        });
        if (data?.users?.length > 0) {
          setShowReportee(data.users);
          if (data.clonned_users.length > 0) {
            const filter = data.users.map((item:any) => ({
              ...item,
              isChecked: data.clonned_users.includes(item.user_id),
            }));
            setShowReportee(filter);
          }
          setClonedReportee(data.clonned_users);
        }
        return data;
      } catch (err) {
        return false;
      }
    };
    fetch();
  }, [currentFinancialYear]);

  const handleConfirmSave = () => {
    setShowConfirmationDialog(false);
    const loggedInUserId = getAuthHeader['x-user_id'] || employee?.id;
    const userList = showReportee
      .filter((item) => item.isChecked)
      .map((item) => item.user_id);
    const payload = {
      manager_id: id || loggedInUserId,
      userIds: userList,
    };
    setButtonDisabled(false);
    setUpdateButtonDisabled(true);
    const fetch = async () => {
      try {
        const response : any = await CommHandler.request({
          url: `${config.apiBaseUrl}/cloning`,
          method: 'POST',
          params: {
            financialYear: currentFinancialYear,
          },
          data: payload,
          headers: getAuthHeader,
        });
        if (response.data?.userIds?.length > 0) {
          showSuccessSnackbar('Cloned users Successfully!');
          setClonedReportee(response.data.userIds);
          setButtonDisabled(true);
        }
        return response;
      } catch (err) {
        showErrorSnackbar('Cloned users Failed');
        return false;
      }
    };
    fetch();
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleCheckboxChange = (id:any) => {
    const selectedClonedUser = showReportee.map((item) => (item.user_id === id ? { ...item, isChecked: !item.isChecked } : item));
    setShowReportee(selectedClonedUser);
    const count = selectedClonedUser.filter((item) => item.isChecked).length;
    if (!isEditMode) {
      setButtonDisabled(!(count >= 1));
    } else {
      setUpdateButtonDisabled(!(count >= 1));
    }
  };
  const handleSaveButtonClick = () => {
    setShowConfirmationDialog(true);
    setEditMode(false);
  };
  const displayHeader = () => (
    <ArtifactWrapper>
      <Grid>
        <Box className="artifact-main-table">
          {/* Header Row */}
          <Box className="table-row-item-cell" style={{ margin: '0px' }}>
            {viewCloneColumn.map((heading: string) => (
              <Box key={heading} className="artifact-table-head">
                {heading}
              </Box>
            ))}
          </Box>

          {/* No Data Message */}
          <Box
            style={{
              textAlign: 'center',
              fontWeight: 400,
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            {displayColumnValues()}
          </Box>
        </Box>
      </Grid>
    </ArtifactWrapper>
  );
  const displayColumnValues = () => (
    <ArtifactWrapper>
      {showReportee && showReportee.length ? (showReportee.map((item) => (
        <div className="table-row-item" key={item.id}>
          {/* First Column */}
          <Box className="artifact-table-head">
            <List>
              <ListItem className="list-artifact">
                <Tooltip title={item.fullName} arrow>
                  <ListItemText className="listItem-text" primary={item.fullName} />
                </Tooltip>
              </ListItem>
            </List>
          </Box>
          {/* Second Column (Designation) */}
          <Box className="artifact-table-head">
            <List>
              <ListItem className="list-artifact">
                <ListItemText className="listItem-text" primary={item.designation} />
              </ListItem>
            </List>
          </Box>
          <Box className="artifact-table-head" style={{ marginRight: '34px' }}>
            <FormControlLabel
              key={item.user_id}
              control={(
                <Checkbox
                  checked={!isEditMode ? (checkCloneArrayExists(item.user_id) || item.isChecked) : item.isChecked}
                  disabled={checkCloneArrayExists(item.user_id) && !isEditMode}
                />
)}
              onChange={() => handleCheckboxChange(item.user_id)}
              label=""
            />
          </Box>
        </div>
      ))) : (
        <Typography
          gutterBottom
          align="center"
          className="key-no-data-text"
        >
          No data to display
        </Typography>
      )}
    </ArtifactWrapper>
  );

  const formContainerStyles = {
    marginTop: '20px',
  };

  const displaySaveButton = () => (
    <div style={{
      display: 'flex', margin: 20, justifyContent: 'end', alignItems: 'center',
    }}
    >
      <Button
        style={{ marginLeft: '9px' }}
        className="addArtifact-btn"
        variant="contained"
        disabled={isButtonDisabled}
        onClick={(e) => handleSaveButtonClick()}
      >
        Save
      </Button>
    </div>
  );
  const handleCancelEditButton = () => {
    setEditMode(false);
    if (showClonedReportee.length > 0) {
      const filtered = showReportee.map((item:any) => ({
        ...item,
        isChecked: showClonedReportee.includes(item.user_id),
      }));
      const count = filtered.filter((item:any) => item.isChecked).length;
      setButtonDisabled(showClonedReportee.length === count);
      setShowReportee(filtered);
    }
  };
  const displayEditCancelButton = () => (
    <div style={{
      display: 'flex', margin: 20, justifyContent: 'end', alignItems: 'center',
    }}
    >
      <Button
        style={{ marginLeft: '9px' }}
        className="addArtifact-btn"
        variant="contained"
        onClick={() => handleCancelEditButton()}
      >
        Cancel
      </Button>
      <Button
        style={{ marginLeft: '9px' }}
        className="addArtifact-btn"
        variant="contained"
        disabled={isUpdateButtonDisabled}
        onClick={(e) => handleSaveButtonClick()}
      >
        Update
      </Button>
    </div>
  );

  const displayEditButton = () => {
    if (showClonedReportee.length > 0 || showReportee.length === 0) {
      return null;
    }
  
    return (
      <div
        style={{
          position: 'relative',
          top: '0%',
          left: '94%',
        }}
      >
        <Button
          style={{ marginLeft: '9px' }}
          className="addArtifact-btn"
          variant="contained"
          onClick={(e) => handleAction()}
        >
          Edit
        </Button>
      </div>
    );
  };
  
  return (
    <>
    {hasClonePermission && (
      displayEditButton()
    )}
    <Grid item xs={12} md={12} lg={12}>
      {displayHeader()}
      {isEditMode ? displayEditCancelButton() : displaySaveButton()}
      <DialogBoxforCloneApproval
        showConfirmationDialog={showConfirmationDialog}
        handleConfirmSave={handleConfirmSave}
        handleCancelConfirmation={handleCancelConfirmation}
      />
    </Grid>
  </>
  );
};

export default ViewClone;
