/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Avatar, Box } from '@mui/material';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import { useDispatch, useSelector } from 'react-redux';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { RootState } from '../../../store/type';
import { User } from '../../Dashboard/components/UserProfile/type';
import { Kra } from '../type';
import { KeyResponsibilityWrapper } from './KeyResponsibility.styled';
import { getAuthHeader } from '../../../utils/utils';

const KeyResponsibility = (props:any) => {
  const { data } = props;
  const selectUser = (state: RootState) => state.user.employee;
  const [responsibilities, setResponsibilities] = useState<any>({});
  const user: User = useSelector(selectUser);
  const { profile } = user ?? {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const designation =
          user.firstName === data.firstName &&
          user.middleName === data.middleName &&
          user.lastName === data.lastName
            ? profile?.designation
            : data?.profile?.designation;
  
        if (!designation) {
          setResponsibilities([]);
          return;
        }
  
        const response = await CommHandler.request({
          url: `${config.adminApiURL}/kra/designation/${designation}`,
          method: 'GET',
          headers: getAuthHeader,
        });
  
        const fetchedResponsibilities = response.data.map(
          (item: Kra) => item.responsibilities
        );
        setResponsibilities(fetchedResponsibilities);
      } catch (err) {
        console.error("Error fetching responsibilities:", err);
        setResponsibilities([]);
      }
    };
  
    fetchData();
  }, [user, data]); 

  function removeHtmlTags(input:any) {
    return input.replace(/<[^>]+>/g, '');
  }

  return (
    <KeyResponsibilityWrapper>
      <Paper className="key-responsibility-paper-wrap">
        <Grid item xs={12} className="key-title-wrap">
          <Typography className="key-title-text" variant="h5" gutterBottom>
            Key Responsibility Areas
          </Typography>
        </Grid>
        <Grid container spacing={2} className="key-container-wrap">

          {' '}
          {responsibilities && responsibilities.length ? (
            responsibilities.map((key) => (
              <Grid item xs={12} key={key} className="key-response-wrapper">
                <Box className="key-icon-text-wrapper" gap={1}>
                  <Avatar className="key-icon-wrap">
                    <OnDeviceTrainingIcon className="key-icon" />
                  </Avatar>
                  <Typography
                    variant="body2"
                    className="key-data-text"
                    gutterBottom
                  >
                    {removeHtmlTags(key)}
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography
              gutterBottom
              align="center"
              className="key-no-data-text"
            >
              No data to display
            </Typography>
          )}

        </Grid>
      </Paper>
    </KeyResponsibilityWrapper>
  );
};

export default KeyResponsibility;
