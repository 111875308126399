/* eslint-disable */
import { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import Typography from '../../components/Typography/Typography';
import { ArtifactWrapper } from './BUArtifact.styled';
import { ListTable } from '../../components';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { getAuthHeader } from '../../utils/utils';

export default function Artifact() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const handleActionClick = (action: any, item: any) => {
    const userId = item?.profile?.user_id;
    if (action.label === 'View') {
      navigate(`/user-artifact-detail/${userId}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingTable(true);
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/users`,
          method: 'GET',
          params: { isActive: true },
          headers: getAuthHeader,
        });
        if (response?.data?.length) {
          setData(response?.data);
        }
      } catch (e) {
        setLoadingTable(false);
      } finally {
        setLoadingTable(false);
      }
    }
    fetchData();
  }, []);

  const col = [
    {
      header: 'Employee Name',
      accessor: 'fullName',
    },
    {
      header: 'Employee Email',
      accessor: 'email',
    },
    {
      header: 'Business Unit',
      accessor: 'business unit',
    },
  ];

  const report = [
    {
      icon: <VisibilityIcon />,
      label: 'View',
      onClick: () => {},
      color: 'primary-icon',
    },
  ];

  return (
    <ArtifactWrapper>
      <div className="artifact-btn-space">
        <div>
          <Typography variant="h1" gutterBottom>
            All Employees Artifacts
          </Typography>
        </div>
      </div>
      <ListTable
        title="Overall"
        tabValue="All"
        columns={col}
        data={data?.sort((a, b) => a?.fullName?.localeCompare(b?.fullName)) || []}
        actions={report}
        onActionClick={(action, item) => {
          handleActionClick(action, item);
        }}
        isStatus={false}
        loading={loadingTable}
      />
    </ArtifactWrapper>
  );
}
